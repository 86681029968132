import * as React from 'react';
import cx from 'classnames';
import DialogWithContent from '../dialog-with-content/dialog-with-content';
import { TextBlockHeader, TextBlockBody } from '../text-block';
import type { TDynamicGrid, TDynamicGridItem } from './dynamic-grid.types';
import { DynamicGridItem } from './dynamic-grid-item';
import { DynamicGridLink } from './dynamic-grid-link';

export const DynamicGrid: React.FC<TDynamicGrid> = function ({
  imageAspectRatio,
  modalImageAspectRatio = '16:9',
  items: baseItems = [],
  verticalStackThreshold = 0,
  className = '',
  headline,
  content,
  onViewItem,
  onClickLink,
  onToggleModal,
  disableModal = false,
  url,
  ...props
}) {
  const [activeId, updateActiveId] = React.useState<string>('');
  const [isModalOpen, updateModalOpen] = React.useState<boolean>(false);
  const [snapClasses, setSnapClasses] = React.useState<string>('');

  const items = baseItems?.filter((item) => Boolean(item));
  const count = items?.length || 0;

  React.useEffect(() => {
    // delay appying the snap classes, otherwise snapping can occur before the user scrolls
    const timer = setTimeout(() => setSnapClasses('snap snap-x snap-px-4 snap-mandatory'), 500);
    return () => clearTimeout(timer);
  }, []);

  if (!count) {
    return null;
  }

  const onClickItemLink = () => {
    onClickLink && onClickLink(activeId);
  };
  const openModal = (id: string) => {
    if (!disableModal) {
      updateActiveId(id);
      onToggleModal && onToggleModal(true);
      onViewItem && onViewItem(id);
      updateModalOpen(true);
    }
  };
  const closeModal = () => {
    onToggleModal && onToggleModal(false);
    updateModalOpen(false);
  };

  const activeItem: TDynamicGridItem =
    items?.find((i) => i.id === activeId) || items?.[0] || ({} as TDynamicGridItem);

  const gridElements: Array<JSX.Element> = [];
  items.forEach((item: TDynamicGridItem) => {
    gridElements.push(
      disableModal ? (
        <DynamicGridLink
          key={item.id}
          url={url}
          imageAspectRatio={imageAspectRatio}
          {...item}
          singular={items.length === 1}
        />
      ) : (
        <DynamicGridItem
          key={item.id}
          imageAspectRatio={imageAspectRatio}
          onItemClick={() => {
            openModal(item.id);
          }}
          {...item}
        />
      )
    );
  });

  const isVerticalStack = count <= verticalStackThreshold;
  const is369 = count % 3 === 0 || count === 5;
  const is4x = !is369 && count > 2 && (count % 2 === 0 || count === 7);
  const is2x = !is369 && (is4x || count % 2 === 0);

  return (
    <section>
      <div
        className={cx('container overflow-hidden py-6', {
          [className]: !!className,
        })}
        data-testid="dynamicGrid"
      >
        <TextBlockHeader>{headline}</TextBlockHeader>
        <TextBlockBody className="pe-8 sm:pe-6" {...props}>
          {content}
        </TextBlockBody>
        {items.length !== 1 ? (
          <div
            className={cx(
              snapClasses,
              'grid-wrapper grid gap-4 overflow-x-auto',
              'sm:w-full sm:grid-flow-row sm:overflow-x-hidden',
              isVerticalStack ? 'grid-flow-row grid-cols-1' : 'grid-flow-col',
              is369 ? 'sm:grid-cols-3' : null,
              is2x ? 'sm:grid-cols-2' : null,
              is4x ? 'lg:grid-cols-4' : null
            )}
          >
            {gridElements}
          </div>
        ) : (
          <div className="justify-self-center md:w-1/3">{gridElements} </div>
        )}

        <DialogWithContent
          id="dynamicGridModalOverlay"
          ariaLabel={activeItem.caption || ''}
          containerAspectRatio={modalImageAspectRatio}
          imageAspectRatio={modalImageAspectRatio}
          imageUrl={activeItem.modalImageUrl}
          imageAltText={activeItem.imageAltText}
          title={activeItem.caption}
          headline={activeItem.headline}
          content={activeItem.content}
          link={activeItem.link}
          isOpen={isModalOpen}
          onClose={closeModal}
          onClickLink={onClickItemLink}
          {...props}
        />
      </div>
    </section>
  );
};
