import { Trans } from 'next-i18next';
import type { FC } from 'react';

type TBookStayFromProps = {
  rate: string;
  dates: string;
};

export const BookStayFromSection: FC<TBookStayFromProps> = ({ rate, dates }) => {
  return (
    <Trans
      ns="rooms"
      i18nKey="exploreStayFrom"
      values={{ rate, dates }}
      components={{ bold: <strong /> }}
    />
  );
};
