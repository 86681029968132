import { sendReward } from '@dx-ui/framework-conductrics';
import type { HotelImage, HotelRoomTypeCategoryCategory } from '@dx-ui/gql-types';
import { BrandLink } from '../brand-buttons/brand-buttons';
import cx from 'classnames';
import { format } from 'date-fns';
import type { TFunction } from 'i18next';
import findIndex from 'lodash/findIndex';
import reduce from 'lodash/reduce';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useHomepageShopQuery } from '../../generated/react-query';
import { GOALS } from '../../helpers/conductricsConstants';
import { digitalData } from '../../helpers/metrics/constants';
import { generateUrl } from '../../helpers/property-utils';
import { useSearchContext } from '../../helpers/providers/search/search-provider';
import { getHotelsRouteParams } from '../../helpers/routing';
import { useGetHomepagePricing } from '../../hooks/use-get-homepage-pricing';
import { useGlobalConfig } from '../../hooks/use-global-config';
import type { TDynamicGrid, TDynamicGridItem } from '../dynamic-grid';
import { DynamicGrid } from '../dynamic-grid';
import { DynamicGridLink } from '../dynamic-grid/dynamic-grid-link';
import { BookStayFromSection } from './BookStayFromSection';
import type { TWithCustomTheme } from '../../helpers/themes/customTheme';
import type { GetHotelHomePageQuery } from '../../generated/types';

type TRoomCategoryImgCategory = Pick<HotelImage, 'altText' | 'variants'> & {
  oneByOneRatioImgUrl?: string;
  threeByTwoRatioImgUrl?: string;
};

export type TRoomCategory = Pick<
  NonNullable<GetHotelHomePageQuery['hotel']>['roomTypeCategories'][number],
  'category' | 'desc' | 'headline' | 'roomTypes'
> & {
  image?: TRoomCategoryImgCategory | null;
};

export type TRoomsOverviewProps = {
  roomCategoriesData: TRoomCategory[];
  wrapperClass?: string | undefined;
};

const createCategoryName = ({
  t,
  category,
  headline,
}: {
  t: TFunction<'rooms'>;
  category?: HotelRoomTypeCategoryCategory | null;
  headline?: string;
}) => (headline ? headline : category ? t(`tabNames.${category}`) : '');

const RoomsOverview = ({
  roomCategoriesData = [],
  wrapperClass,
}: TWithCustomTheme<TRoomsOverviewProps>) => {
  const { relativeUrl } = generateUrl(useRouter(), 'rooms');
  const { t } = useTranslation('rooms');
  const { searchState } = useSearchContext();
  const language = useRouter().locale || 'en';
  const router = useRouter();
  const { ctyhocn } = getHotelsRouteParams(router);
  const { isTailored } = useGlobalConfig();
  const numRooms = searchState?.rooms?.length || 1;
  const numAdults = searchState?.rooms?.reduce((acc, room) => acc + room.adults, 0) || 1;
  const numChildren =
    searchState?.rooms?.reduce((acc, room) => acc + (room.children?.length || 0), 0) || 0;

  const { data: pricingData, isLoading } = useHomepageShopQuery(
    {
      ctyhocn,
      language,
      arrivalDate: searchState?.dates?.arrivalDate
        ? format(searchState.dates.arrivalDate, 'yyyy-MM-dd')
        : '',
      departureDate: searchState?.dates?.departureDate
        ? format(searchState.dates.departureDate, 'yyyy-MM-dd')
        : '',
      guestLocationCountry: 'US',
      numRooms,
      numAdults,
      numChildren,
      specialRates: {
        aaa: searchState?.specialRates?.aaaRate,
        aarp: searchState?.specialRates?.aarpRate,
        corporateId: searchState?.specialRates?.corporateCode,
        governmentMilitary: searchState?.specialRates?.governmentOrMilitaryRate,
        groupCode: searchState?.specialRates?.groupCode,
        offerId: searchState?.specialRates?.offerId?.id,
        pnd: searchState?.specialRates?.pnd?.pnd,
        promoCode: searchState?.specialRates?.promoCode,
        senior: searchState?.specialRates?.seniorRate,
        travelAgent: searchState?.specialRates?.travelAgentRate,
      },
    },
    { enabled: !!searchState }
  );

  const { rate, dateString, availabilityStatus, feeTransparencyInfo } = useGetHomepagePricing({
    searchState,
    pricingData,
    language,
  });

  const dynamicGridData = reduce(
    roomCategoriesData,
    (result: TDynamicGridItem[], value: TRoomCategory) => {
      let imageVariant;
      if (value?.image?.variants && value.image.variants.length > 0) {
        const imageVariantIndex = findIndex(value.image.variants, (v) => v.size === 'md');
        if (imageVariantIndex !== -1) imageVariant = value.image.variants[imageVariantIndex];
      }
      const src = isTailored
        ? value.image?.oneByOneRatioImgUrl
        : value.image?.threeByTwoRatioImgUrl || imageVariant?.url;

      if (value?.roomTypes?.length > 0)
        result.push({
          id: value.category || '',
          caption: createCategoryName({
            t,
            category: value.category,
            headline: value.headline || '',
          }),
          imageAltText: value.image?.altText || '',
          link: {
            adaDescription: value.desc ? value.desc : '',
            url: '',
          },
          imageUrl: src || '',
        });
      return result;
    },
    []
  );

  const gridProps: TDynamicGrid = {
    imageAspectRatio: isTailored ? '1:1' : '3:2',
    headline: undefined,
    content: undefined,
    items: dynamicGridData,
    disableModal: true,
    url: `${relativeUrl}?category=`,
    onClickLink: async (id) => {
      await router.push(`${relativeUrl}/?category=${id}`);
    },
  };

  const [roomsAvailable, setRoomsAvailable] = useState(false);
  useEffect(() => {
    if (availabilityStatus === 'AVAILABLE') {
      setRoomsAvailable(true);
    }
  }, [availabilityStatus]);

  if (availabilityStatus === 'AVAILABLE') {
    if (!(digitalData.conductrics_traits as string[]).includes('sold-out:no')) {
      (digitalData.conductrics_traits as string[]).push('sold-out:no');
    }
  }
  if (!isLoading && availabilityStatus !== 'AVAILABLE') {
    if (!(digitalData.conductrics_traits as string[]).includes('sold-out:yes')) {
      (digitalData.conductrics_traits as string[]).push('sold-out:yes');
    }
  }

  return (
    <div
      className={cx(
        'py-8 text-center lg:py-10',
        {
          'bg-bg-alt': !isTailored,
          'bg-bg': isTailored,
        },
        wrapperClass
      )}
    >
      <h2
        className={cx('heading-2xl lg:heading-3xl', {
          'mb-2': !isTailored,
          'heading-4xl': isTailored,
        })}
      >
        {t('roomsAndSuites')}
      </h2>
      <div className="block sm:hidden" data-testid="dynamic-grid-link">
        <div className="flex overflow-x-auto">
          {gridProps.items.map((item) => {
            return (
              <div className="mx-2 my-4 flex-none first:ms-4 last:me-4" key={item.id}>
                <div className="flex flex-col items-center justify-center gap-3">
                  <DynamicGridLink
                    url={gridProps.url}
                    imageAspectRatio={gridProps.imageAspectRatio}
                    {...item}
                    singular={gridProps.items.length === 1}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="hidden sm:block">
        <DynamicGrid {...gridProps} />
      </div>
      {roomsAvailable && !!rate ? (
        <div className="pb-4">
          <BookStayFromSection rate={rate} dates={dateString || ''} />
        </div>
      ) : null}
      {feeTransparencyInfo ? (
        <div
          className={cx('p-4 pt-0 text-center text-sm sm:pb-2', {
            'text-text-alt': !isTailored,
          })}
          dangerouslySetInnerHTML={{ __html: feeTransparencyInfo }}
        />
      ) : null}
      <div className="flex w-full justify-center">
        <BrandLink
          url={relativeUrl}
          onClick={async () => {
            await Promise.all([
              sendReward(GOALS.ROOMS_AND_SUITES_ENGAGEMENT),
              sendReward(GOALS.VIEW_ALL_ROOMS_CTA),
            ]);
          }}
          label={t('exploreRooms')}
          variant="solid"
        />
      </div>
    </div>
  );
};

export default RoomsOverview;
