import { useState, useRef } from 'react';
import cx from 'classnames';
import { useRect } from '@dx-ui/utilities-use-rect';
import { GetHeightForWidthAndAspectRatio } from '../responsive-image/responsive-image';
import type { TDynamicGridItemAndAspectRatios } from './dynamic-grid.types';
import { Image } from '../image/Image';

const DynamicGridItem: React.FC<TDynamicGridItemAndAspectRatios> = function (item) {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const [imgLoaded, setImgLoaded] = useState(false);
  const { id, onItemClick = () => undefined } = item;
  const onClickGridItem = () => {
    onItemClick && onItemClick(id);
  };
  // TODO: NHCBP-3494 - Tailwind utitilies for grid-* classes (see grid.css):
  // TODO: NHCBP-3494 - Tailwind focus:ring utility not working - classNames on "button":
  // focus:ring-2 focus:ring-primary focus:ring-opacity-50

  const ar = item?.imageAspectRatio;
  const height = GetHeightForWidthAndAspectRatio(rect?.width ?? 0, ar);

  if (!item) {
    return null;
  }

  return (
    <button
      key={id}
      onClick={onClickGridItem}
      className="snap group snap-start overflow-y-hidden sm:w-auto"
      style={{ height }}
      data-testid="dynamicGridItemButton"
      type="button"
    >
      <div className="bg-bg-alt relative sm:overflow-hidden">
        <div className={cx('absolute flex h-full w-full items-end', { 'z-2': imgLoaded })}>
          <div className="from-bg-inverse size-full bg-gradient-to-t to-transparent">
            <div className="flex size-full transform-gpu items-end duration-200 ease-in-out group-hover:-translate-y-1/3">
              <div
                className={cx(
                  'text-text-inverse mx-auto w-full py-8 px-3 text-2xl font-bold uppercase leading-tight',
                  'sm:py-4 sm:text-base lg:py-6 lg:text-xl xl:text-2xl',
                  { hidden: !imgLoaded }
                )}
                data-testid="dyamincGridItemButtonText"
              >
                {imgLoaded && item.caption ? item.caption : ''}
              </div>
            </div>
          </div>
        </div>
        <div ref={ref}>
          <Image
            id={`dynamic-grid-item-${id}`}
            aspectRatio={ar}
            src={item.imageUrl}
            alt={item.imageAltText}
            width={rect?.width ?? 0}
            onLoad={() => setImgLoaded(true)}
          />
        </div>
        {!imgLoaded ? (
          <div className="z-2 text-text-inverse absolute bottom-0 mx-auto w-full px-3 py-8 text-2xl font-bold uppercase leading-tight sm:py-4 sm:text-base lg:py-6 lg:text-xl xl:text-2xl">
            {item.caption ? item.caption : ''}
          </div>
        ) : null}
      </div>
    </button>
  );
};

export { DynamicGridItem };
