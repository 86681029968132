import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import cx from 'classnames';
import type * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useRect } from '@dx-ui/utilities-use-rect';
import { getAspectRatioUrl } from '@dx-ui/utilities-images';
import { GOALS } from '../../helpers/conductricsConstants';
import { useGlobalConfig } from '../../hooks/use-global-config';
import { Image } from '../image/Image';
import type { TDynamicGridItemAndAspectRatios } from './dynamic-grid.types';

export const DynamicGridLink: React.FC<TDynamicGridItemAndAspectRatios> = function (item) {
  const { isTailored } = useGlobalConfig();
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const [imgLoaded, setImgLoaded] = useState(false);
  const { id } = item;
  const ar = item?.imageAspectRatio;
  const imageUrl = useMemo(
    () => getAspectRatioUrl({ src: item?.imageUrl, width: rect?.width ?? 0 }).twoXimgUrl || '',
    [item?.imageUrl, rect?.width]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {item ? (
        <a
          href={`${item.url}${id}`}
          key={id}
          className={cx('snap snap-start group w-64 overflow-y-hidden sm:w-auto', {
            'md:w-1/3': item?.singular,
            'border-border-alt rounded-lg border': !isTailored,
          })}
          data-testid="dynamicGridItemButton"
          onClick={async () => {
            await sendRewardAsync(`g-RoomCategory-${id}`);
            await sendRewardAsync(GOALS.ROOMS_AND_SUITES_ENGAGEMENT);
          }}
        >
          <div className="bg-bg-alt group relative sm:overflow-hidden">
            <div
              className={cx('absolute flex h-full w-full items-end', {
                'z-2': imgLoaded,
              })}
            />
            <div ref={ref} className={cx({ 'duration-300 group-hover:scale-125': isTailored })}>
              <Image
                className={cx({
                  'group-hover:opacity-50 duration-300 ease-in-out': isTailored,
                })}
                alt={item.imageAltText}
                src={imageUrl}
                width={rect?.width ?? 0}
                aspectRatio={ar}
                onLoad={() => setImgLoaded(true)}
              />
            </div>
          </div>
          <div
            className={cx(
              'w-full p-3 font-bold capitalize leading-tight group-hover:underline group-focus:underline',
              {
                'bg-bg group-hover:text-primary group-focus:text-primary text-left text-xl':
                  !isTailored,
                'bg-bg-alt text-text-alt text-center text-2xl': isTailored,
              }
            )}
          >
            {item.caption}
          </div>
        </a>
      ) : null}
    </>
  );
};
